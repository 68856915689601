import { type Configuration, createApp } from '@praktikumio/space';
import { SupportedLocale } from '@stafftastic/api';

import fontsCssURL from './styles/fonts.scss?url';
import appCssURL from './styles/space.scss?url';
import tokensCssURL from './styles/tokens.scss?url';
import {
  appendStylesheetToDocument,
  getAttributesFromScript,
  getCurrentScript,
  isSupportedLocale,
  prependStylesheetToShadowRoot,
  showRuntimeError,
  showRuntimeWarning,
} from './utils';

const knownAttributes = ['space-id', 'target', 'param-namespace', 'locale'] as const;

const mount = (shadowRoot: ShadowRoot, configuration: Configuration) => {
  appendStylesheetToDocument(fontsCssURL);
  prependStylesheetToShadowRoot(shadowRoot, appCssURL);
  prependStylesheetToShadowRoot(shadowRoot, tokensCssURL);

  const modalHost = document.createElement('div');
  modalHost.setAttribute('id', 'modals');
  shadowRoot.appendChild(modalHost);

  const appHost = document.createElement('div');
  shadowRoot.appendChild(appHost);

  const vueApp = createApp(
    configuration,
    modalHost,
  );

  vueApp.mount(appHost);
};

const init = async () => {
  const currentScript = getCurrentScript('script[data-space-id]');
  if (!currentScript) {
    return showRuntimeError('Cannot find current script element');
  }
  const {
    locale,
    target,
    'space-id': spaceId,
    'param-namespace': paramNamespace,
  } = getAttributesFromScript(currentScript, [...knownAttributes]);

  if (!target) {
    return showRuntimeError('Missing "data-target" attribute');
  }

  const mountTarget = document.querySelector(`#${target}`);
  if (!mountTarget) {
    return showRuntimeError(`Cannot find element with id attribute set as "${target}"`);
  }

  if (!spaceId) {
    return showRuntimeError('Missing "data-space-id" attribute');
  }

  if (locale && !isSupportedLocale(locale)) {
    showRuntimeWarning(`Invalid locale "${locale}". Using defaulting to "de".`);
  }

  const shadowRoot = mountTarget.attachShadow({ mode: 'open' });
  await mount(shadowRoot, {
    spaceId,
    paramNamespace,
    locale: locale && isSupportedLocale(locale)
      ? locale
      : SupportedLocale.DE,
  });
};

init();
